npm <template>

  <div style="height: 100vh">
    <div style="padding-left: 15px;font-size: 18px">
      <p>张数 ：<input v-model="pageRows"  style="width: 80px" @input="pageRowsInput" type="number"> 卡号 ：<input v-model="card4No" style="width: 200px"  @input="handleInput" type="number" >
        <button @click ="printElement()" style="margin-left:10px;width: 180px;background-color: #096c0b; height: 36px;vertical-align: center;border:white 1px solid;border-radius: 10px">Lodop打印</button></p>
    </div>
    <div  style="background-color: #4AB7BD;height: auto;padding-top: 20px;">
      <div id="printContent" style="-webkit-font-smoothing:antialiased;font-weight: lighter">
        <div   style="padding-bottom:70px;line-height:6px; font-size: 12px; text-align: center;width: 300px;background-color: #FFFFFF;margin: auto">
          <p style="padding-top: 40px;"><img src='/image/safeway_logo.png' width="250px"/></p>
          <div style="text-align: left;">
            <p class="container" style="padding-left: 10px; text-align: center;" ><span style="display: inline-block;width: 40%; text-align: right; " v-html="'Store' + '&nbsp;'.repeat(2*1) + ''+storeNo"></span><span style="display: inline-block;width: 60%; text-align: left; padding-left: 10px" v-html="salesPerson"></span></p>
            <p style="padding-left: 10px;  text-align: center;"><span style="display: inline-block; text-align: center; " v-html="'Main:' +phone +''"></span></p>
            <p style="padding-left: 10px;  text-align: center;"><span style="display: inline-block; text-align: center; " v-html="address +''"></span></p>
            <p style="padding-left: 10px;  text-align: center;"><span style="display: inline-block; text-align: center; " v-html="city +'&nbsp;'.repeat(2*2) +zip"></span></p>
            <p style="padding-left: 10px; padding-top: 20px; font-weight: bold;  text-align: center;"><span style="display: inline-block; text-align: center; " v-html="'GROCERY'"></span></p>
           <p style="padding-top: 1px;"></p>
            <div style="font-size:11px;" v-if="showProduceData" v-for="(item, index) in showProduceData" :key="index">
              <p style="padding-left: 20px;" v-html="item.name"></p>
              <p style="padding-left: 30px;" v-html="item.productCode + '&nbsp;'.repeat(36*1) + item.price+' B'"></p>
            </div>
            <p style="padding-top: 10px;"></p>
            <p style="padding-left: 40px;" v-html="'TAX'+ '&nbsp;'.repeat(23*2) + ''+taxPrice"></p>
            <p style="padding-left: 20px;"><span  v-html="'****  BALANCE' + '&nbsp;'.repeat(35*1)"></span><span v-html="''+totalPrice"></span></p>
            <p style="padding-left: 20px;"><span  v-html="'-----------------------------------------------------------------'"/></p>
            <p style="padding-left: 20px;" v-html="'Debit' + '&nbsp;'.repeat(2*1) + 'Purchase'+'&nbsp;'.repeat(2*2)  +orderDate"></p>
            <p style="padding-left: 20px;" v-html="'CARD'+ '&nbsp;'.repeat(2*1) +'#'+ '&nbsp;'.repeat(2*1) +'************'+card4No+ '&nbsp;'.repeat(2*1) +'PARMARY'"></p>
            <p style="padding-left: 20px;" v-html="'TOTAL'+ '&nbsp;'.repeat(1) +'TRANSACTION'+ '&nbsp;'.repeat(1) +'AMOUNT:'+ '&nbsp;'.repeat(2*1)+ totalPrice"></p>
            <p style="padding-left: 20px;" v-html="'CASH'+ '&nbsp;'.repeat(1) +'BACK'+ '&nbsp;'.repeat(1) +'AMOUNT:'+ '&nbsp;'.repeat(19*1)+ '0.00'"></p>
            <p style="padding-left: 20px;" v-html="'REF:'+ '&nbsp;'.repeat(2*1)+seqNo+ '&nbsp;'.repeat(4)+'AUTH:'+ '&nbsp;'.repeat(2*1)+'000'+auth"></p>
            <p style="padding-left: 20px;"><span  v-html="'-----------------------------------------------------------------'"/></p>
            <p style="padding-left: 20px;" v-html="'AL'+ '&nbsp;'.repeat(1)+'Debit'"></p>
            <p style="padding-left: 20px;" v-html="'AID:'+ '&nbsp;'.repeat(2) +'A0000000' +aId5No"></p>
            <p style="padding-left: 20px;" v-html="'TVR:'+ '&nbsp;'.repeat(2)  +tvr"></p>
            <p style="padding-left: 20px;" v-html="'TSI:'+ '&nbsp;'.repeat(2)  +tsi"></p>
            <p style="padding-top: 1px;"></p>
            <p style="padding-left: 60px;" v-html="'Debit:'+ '&nbsp;'.repeat(36)  +totalPrice"></p>
            <p style="padding-top: 1px;"></p>
            <p style="padding-left: 60px;" v-html="'CHANGE:'+ '&nbsp;'.repeat(30)  +'0.00'"></p>
            <p style="padding-left: 20px;" v-html="'TOTAL NUMBER OF ITEMS SOLD =  '+ '&nbsp;'.repeat(4)+iptRows"></p>
            <p style="padding-left: 20px;" v-html="orderDate+ '&nbsp;'.repeat(4)  +storeNo+ '&nbsp;'.repeat(4) +codeFrist+ '&nbsp;'.repeat(4) +codeSecend+ '&nbsp;'.repeat(6)+codeThird"></p>
            <p style="padding-top: 1px;"></p>
            <p style="text-align: center" v-html="'Thank you for shopping at Our Store'"></p>
            <p style="text-align: center" v-html="'Come Again Son'"></p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>

</style>
<script>
import {initApkHub, newestApkUrl, queryApkHub} from "@/api/index";
import QRCode from 'qrcode'
import Vue from 'vue';
import { Cell, CellGroup } from 'vant';
import { Circle } from 'vant';
import { Form } from 'vant';
import { Field } from 'vant';
import { Toast } from 'vant';
import { Image as VanImage } from 'vant';
import { Card } from 'vant';
import { Loading } from 'vant';
import { Overlay } from 'vant';
import { Notify } from 'vant';
import JsBarcode from 'jsbarcode'
Vue.use(Notify);
Vue.use(Overlay);
Vue.use(Loading);
Vue.use(Card);
Vue.use(VanImage);
Vue.use(Toast);
Vue.use(Form);
Vue.use(Field);
Vue.use(Circle);
Vue.use(Cell);
Vue.use(CellGroup);

export default {
  data () {
    return {
      storeNo:'',
      phone:'',
      city:'',
      salesPerson:'Dir Nicole Bently',
      address:'',
      tempAddress:'',
      seq4No:'',
      issuer:'',
      iptRowsFull:'',
      pageRows:1,
      iptRows:3,
      showIptRows:'',
      itemPrice:5.50,
      tax10:0.025,
      taxPrice:0.0,
      totalPrice:0.0,
      subTotalPrice:0.0,
      genRows:3,
      card4No:9311,
      seqNo:304740,
      auth:34567,
      tvr:8000048000,
      tsi:6800,
      zip:65202,
      barcodeValue:'9900300230130764390',
      orderDate:'',
      aId5No:925650,
      code7No:1911123,
      codeFrist:98,
      codeSecend:21,
      codeThird:8895,
      showProduceData:[],
      iceRows:0,
      storeData:[{
        "storeNo": "654",
        "address": "2096 Mountain Blvd",
        "city": "Oakland, CA",
        "zip": "94611",
        "phone": "(510) 482-2266",
        "salesPerson": "Smiling Squirrel"
      }, {
        "storeNo": "908",
        "address": "3550 Fruitvale Ave",
        "city": "Oakland, CA",
        "zip": "94602",
        "phone": "(510) 530-7067",
        "salesPerson": "Elegant Fig"
      }, {
        "storeNo": "3132",
        "address": "5100 Broadway",
        "city": "Oakland, CA",
        "zip": "94611",
        "phone": "(510) 285-0782",
        "salesPerson": "Furious Strawberry"
      }, {
        "storeNo": "1119",
        "address": "3747 Grand Ave",
        "city": "Oakland, CA",
        "zip": "94610",
        "phone": "(510) 465-4187",
        "salesPerson": "Grumpy Chef"
      }, {
        "storeNo": "638",
        "address": "4100 Redwood Rd",
        "city": "Oakland, CA",
        "zip": "94619",
        "phone": "(510) 531-0565",
        "salesPerson": "Elegant Popcorn"
      }, {
        "storeNo": "2870",
        "address": "6310 College Ave",
        "city": "Oakland, CA",
        "zip": "94618",
        "phone": "(510) 985-0012",
        "salesPerson": "Loving Topaz"
      }, {
        "storeNo": "967",
        "address": "2 Camino Sobrante",
        "city": "Orinda, CA",
        "zip": "94563",
        "phone": "(925) 254-8792",
        "salesPerson": "Clever Tree"
      }, {
        "storeNo": "969",
        "address": "1355 Moraga Way",
        "city": "Moraga, CA",
        "zip": "94556",
        "phone": "(925) 376-9492",
        "salesPerson": "Rainbow Gummy Bear"
      }, {
        "storeNo": "3125",
        "address": "3889 San Pablo Ave",
        "city": "Emeryville, CA",
        "zip": "94608",
        "phone": "(510) 450-1200",
        "salesPerson": "Grumpy Goose"
      }, {
        "storeNo": "2453",
        "address": "1550 Shattuck Ave",
        "city": "Berkeley, CA",
        "zip": "94709",
        "phone": "(510) 841-7942",
        "salesPerson": "Jovial Pear"
      }, {
        "storeNo": "2448",
        "address": "1030 SW Jefferson St",
        "city": "Portland, OR",
        "zip": "97201",
        "phone": "(503) 205-1849",
        "salesPerson": "Waving Rockstar"
      }, {
        "storeNo": "2790",
        "address": "1303 NW Lovejoy St",
        "city": "Portland, OR",
        "zip": "97209",
        "phone": "(503) 205-6746",
        "salesPerson": "Angry Agent"
      }, {
        "storeNo": "505",
        "address": "5415 SW Beaverton Hwy",
        "city": "Portland, OR",
        "zip": "97221",
        "phone": "(503) 246-1713",
        "salesPerson": "Rainbow Dragon"
      }, {
        "storeNo": "3134",
        "address": "2800 SE Hawthorne Blvd",
        "city": "Portland, OR",
        "zip": "97214",
        "phone": "(503) 236-7070",
        "salesPerson": "Jolly Acai"
      }, {
        "storeNo": "1612",
        "address": "1100 NE Broadway",
        "city": "Portland, OR",
        "zip": "97232",
        "phone": "(503) 528-0500",
        "salesPerson": "Unique Olive"
      }, {
        "storeNo": "3136",
        "address": "8145 SW Barbur Blvd",
        "city": "Portland, OR",
        "zip": "97219",
        "phone": "(503) 452-6068",
        "salesPerson": "Pure Carrot"
      }, {
        "storeNo": "1627",
        "address": "3930 SE Powell Blvd",
        "city": "Portland, OR",
        "zip": "97202",
        "phone": "(503) 772-4440",
        "salesPerson": "Wonder Ice-cream"
      }, {
        "storeNo": "1523",
        "address": "4515 SE Woodstock Blvd",
        "city": "Portland, OR",
        "zip": "97206",
        "phone": "(503) 788-7600",
        "salesPerson": "Loving Croissant"
      }, {
        "storeNo": "509",
        "address": "5920 NE Martin Luther King Blvd",
        "city": "Portland, OR",
        "zip": "97211",
        "phone": "(503) 528-0580",
        "salesPerson": "Swordplay69"
      }, {
        "storeNo": "2690",
        "address": "4320 SE King Rd",
        "city": "Portland, OR",
        "zip": "97222",
        "phone": "(503) 303-1441",
        "salesPerson": "Sporty Eagle"
      }, {
        "storeNo": "1460",
        "address": "1781 Forest Dr",
        "city": "Annapolis, MD",
        "zip": "21401",
        "phone": "(410) 626-2710",
        "salesPerson": "Smiling Topaz"
      }, {
        "storeNo": "2629",
        "address": "2635 Housley Rd",
        "city": "Annapolis, MD",
        "zip": "21401",
        "phone": "(410) 224-8890",
        "salesPerson": "Stunning Deer"
      }, {
        "storeNo": "2764",
        "address": "1451 S Ritchie Hwy",
        "city": "Arnold, MD",
        "zip": "21012",
        "phone": "(410) 919-1000",
        "salesPerson": "Moist Roll"
      }, {
        "storeNo": "2608",
        "address": "52 W Central Ave",
        "city": "Edgewater, MD",
        "zip": "21037",
        "phone": "(410) 919-1156",
        "salesPerson": "Cute Mousse"
      }, {
        "storeNo": "1351",
        "address": "540 Benfield Rd",
        "city": "Severna Park, MD",
        "zip": "21146",
        "phone": "(410) 647-9487",
        "salesPerson": "Rollie"
      }, {
        "storeNo": "1539",
        "address": "4211 Mountain Rd",
        "city": "Pasadena, MD",
        "zip": "21122",
        "phone": "(410) 439-4560",
        "salesPerson": "Super Pizza"
      }, {
        "storeNo": "1596",
        "address": "2644 Chapel Lake Dr",
        "city": "Gambrills, MD",
        "zip": "21054",
        "phone": "(410) 451-4750",
        "salesPerson": "Divine Yogurt"
      }, {
        "storeNo": "1939",
        "address": "1925 Main St",
        "city": "Chester, MD",
        "zip": "21619",
        "phone": "(410) 604-0656",
        "salesPerson": "Unique Bottle"
      }, {
        "storeNo": "1804",
        "address": "4101 Northview Dr",
        "city": "Bowie, MD",
        "zip": "20716",
        "phone": "(301) 262-7992",
        "salesPerson": "Rainbow Popcorn"
      }, {
        "storeNo": "2713",
        "address": "12410 Fairwood Pkwy",
        "city": "Bowie, MD",
        "zip": "20720",
        "phone": "(301) 867-0340",
        "salesPerson": "Sunny Warrior"
      }, {
        "storeNo": "1466",
        "address": "1121 N Circle Dr",
        "city": "Colorado Springs, CO",
        "zip": "80909",
        "phone": "(719) 573-6023",
        "salesPerson": "Passionate Pear"
      }, {
        "storeNo": "812",
        "address": "2210 N Wahsatch Ave",
        "city": "Colorado Springs, CO",
        "zip": "80907",
        "phone": "(719) 471-7243",
        "salesPerson": "Benno"
      }, {
        "storeNo": "835",
        "address": "5060 N Academy Blvd",
        "city": "Colorado Springs, CO",
        "zip": "80918",
        "phone": "(719) 593-1343",
        "salesPerson": "Moist Zebra"
      }, {
        "storeNo": "1499",
        "address": "2890 N Powers",
        "city": "Colorado Springs, CO",
        "zip": "80922",
        "phone": "(719) 573-4860",
        "salesPerson": "Rich Steak"
      }, {
        "storeNo": "1792",
        "address": "1425 S Murray Blvd",
        "city": "Colorado Springs, CO",
        "zip": "80916",
        "phone": "(719) 591-0880",
        "salesPerson": "Special Rockstar"
      }, {
        "storeNo": "2816",
        "address": "1920 S Nevada Ave",
        "city": "Colorado Springs, CO",
        "zip": "80905",
        "phone": "(719) 636-5255",
        "salesPerson": "Superb Pear"
      }, {
        "storeNo": "879",
        "address": "4405 Centennial Blvd",
        "city": "Colorado Springs, CO",
        "zip": "80907",
        "phone": "(719) 593-8805",
        "salesPerson": "Hilarious Koala"
      }, {
        "storeNo": "1286",
        "address": "3275 W Colorado Ave",
        "city": "Colorado Springs, CO",
        "zip": "80904",
        "phone": "(719) 473-6307",
        "salesPerson": "Exceptional Rice"
      }, {
        "storeNo": "803",
        "address": "7055 Austin Bluffs Pkwy",
        "city": "Colorado Springs, CO",
        "zip": "80923",
        "phone": "(719) 264-8650",
        "salesPerson": "Wonder Bird"
      }, {
        "storeNo": "2839",
        "address": "840 Village Ctr Dr",
        "city": "Colorado Springs, CO",
        "zip": "80919",
        "phone": "(719) 548-1404",
        "salesPerson": "Precious Watermelon"
      }, {
        "storeNo": "1873",
        "address": "771 Thornton Pkwy",
        "city": "Denver, CO",
        "zip": "80229",
        "phone": "(303) 280-2245",
        "salesPerson": "Joyful Emerald"
      }, {
        "storeNo": "1635",
        "address": "10300 Federal Blvd",
        "city": "Denver, CO",
        "zip": "80260",
        "phone": "(303) 469-0990",
        "salesPerson": "Fabulous Hotdog"
      }, {
        "storeNo": "892",
        "address": "3840 E 104th Ave",
        "city": "Thornton, CO",
        "zip": "80233",
        "phone": "(303) 255-7347",
        "salesPerson": "Stunning Giraffe"
      }, {
        "storeNo": "881",
        "address": "500 E 120th Ave",
        "city": "Denver, CO",
        "zip": "80233",
        "phone": "(303) 450-2462",
        "salesPerson": "Epic Jello"
      }, {
        "storeNo": "1998",
        "address": "7561 W 80th Ave",
        "city": "Arvada, CO",
        "zip": "80003",
        "phone": "(303) 420-0318",
        "salesPerson": "Bulky Goose"
      }, {
        "storeNo": "244",
        "address": "3800 W 44th Ave",
        "city": "Denver, CO",
        "zip": "80211",
        "phone": "(303) 458-8418",
        "salesPerson": "Bulky Blueberry"
      }, {
        "storeNo": "2624",
        "address": "3602 W 144th Ave",
        "city": "Broomfield, CO",
        "zip": "80023",
        "phone": "(303) 209-2405",
        "salesPerson": "Divine Coffee"
      }, {
        "storeNo": "1248",
        "address": "2660 N Federal Blvd",
        "city": "Denver, CO",
        "zip": "80211",
        "phone": "(303) 209-2405",
        "salesPerson": "LG82"
      }, {
        "storeNo": "1479",
        "address": "3900 Wadsworth Blvd",
        "city": "Wheat Ridge, CO",
        "zip": "80033",
        "phone": "(303) 456-2670",
        "salesPerson": "Slimy Rocket"
      }, {
        "storeNo": "2246",
        "address": "757 E 20th Ave",
        "city": "Denver, CO",
        "zip": "80205",
        "phone": "(303) 861-8169",
        "salesPerson": "Waving Slippers"
      }, {
        "storeNo": "1299",
        "address": "10100 N Newport Hwy",
        "city": "Spokane, WA",
        "zip": "99218",
        "phone": "(509) 465-2576",
        "salesPerson": "Joyful Strawberry"
      }, {
        "storeNo": "265",
        "address": "6520 N Nevada",
        "city": "Spokane, WA",
        "zip": "99208",
        "phone": "(509) 489-1250",
        "salesPerson": "Unique Lemon"
      }, {
        "storeNo": "3248",
        "address": "902 W Francis Ave",
        "city": "Spokane, WA",
        "zip": "99205",
        "phone": "(509) 327-4880",
        "salesPerson": "Joyful Lemon"
      }, {
        "storeNo": "206",
        "address": "9001 N Indian Trail Rd",
        "city": "Spokane, WA",
        "zip": "99208",
        "phone": "(509) 465-8110",
        "salesPerson": "Furious Star"
      }, {
        "storeNo": "1494",
        "address": "2507 W Wellesley Ave",
        "city": "Spokane, WA",
        "zip": "99205",
        "phone": "(509) 327-4593",
        "salesPerson": "Cute Plum"
      }, {
        "storeNo": "1799",
        "address": "3919 N Market St",
        "city": "Spokane, WA",
        "zip": "99207",
        "phone": "(509) 484-5195",
        "salesPerson": "Green Tiramisu"
      }, {
        "storeNo": "342",
        "address": "1616 W Northwest Blvd",
        "city": "Spokane, WA",
        "zip": "99205",
        "phone": "(509) 327-4974",
        "salesPerson": "Karate Comedian"
      }, {
        "storeNo": "3255",
        "address": "933 E Mission Ave",
        "city": "Spokane, WA",
        "zip": "99202",
        "phone": "(509) 482-1987",
        "salesPerson": "Precious Superstar"
      }, {
        "storeNo": "246",
        "address": "8851 E Trent Ave",
        "city": "Spokane, WA",
        "zip": "99212",
        "phone": "(509) 924-9014",
        "salesPerson": "Peaceful Madeleine"
      }, {
        "storeNo": "1242",
        "address": "2509 E 29th Ave",
        "city": "Spokane, WA",
        "zip": "99223",
        "phone": "(509) 532-0822",
        "salesPerson": "Hysterical Cheese"
      }, {
        "storeNo": "995",
        "address": "1335 Webster St",
        "city": "San Francisco, CA",
        "zip": "94115",
        "phone": "(415) 921-4557",
        "salesPerson": "Beautiful Koala"
      }, {
        "storeNo": "1711",
        "address": "15 Marina Blvd",
        "city": "San Francisco, CA",
        "zip": "94123",
        "phone": "(415) 563-4946",
        "salesPerson": "Unique Emerald"
      }, {
        "storeNo": "1507",
        "address": "2020 Market St",
        "city": "San Francisco, CA",
        "zip": "94114",
        "phone": "(415) 861-7660",
        "salesPerson": "Green Singer"
      }, {
        "storeNo": "2646",
        "address": "735 7th Ave",
        "city": "San Francisco, CA",
        "zip": "94118",
        "phone": "(415) 683-4070",
        "salesPerson": "Fierce Smores"
      }, {
        "storeNo": "1490",
        "address": "2300 16th St Unit 203",
        "city": "San Francisco, CA",
        "zip": "94103",
        "phone": "(415) 575-1120",
        "salesPerson": "Bling Bling Lemon"
      }, {
        "storeNo": "4601",
        "address": "145 Jackson St",
        "city": "San Francisco, CA",
        "zip": "94111",
        "phone": "(415) 982-3112",
        "salesPerson": "Dazzling Pie"
      }, {
        "storeNo": "2452",
        "address": "1200 Irving St",
        "city": "San Francisco, CA",
        "zip": "94122",
        "phone": "(415) 661-3220",
        "salesPerson": "Rainbow Jam"
      }, {
        "storeNo": "2606",
        "address": "298 King St",
        "city": "San Francisco, CA",
        "zip": "94107",
        "phone": "(415) 633-1001",
        "salesPerson": "Wonder Tart"
      }, {
        "storeNo": "3066",
        "address": "375 32nd Ave",
        "city": "San Francisco, CA",
        "zip": "94121",
        "phone": "(415) 683-4102",
        "salesPerson": "Dancing Pie"
      }, {
        "storeNo": "667",
        "address": "5290 Diamond Heights Blvd",
        "city": "San Francisco, CA",
        "zip": "94131",
        "phone": "(415) 824-7744",
        "salesPerson": "Heavy tool"
      }],
      productData:[{"name":"SO DELICIOUS VANILLA BEAN 16 OZ","price":"6.49","productCode":"0074447347711"},
        {"name":"TRU FRU PINEAPPLE WHITE 8 OZ","price":"7.99","productCode":"0085001211751"},
        {"name":"SO DELICIOUS VANILLA BEAN 1PINT","price":"4.49","productCode":"0074447347011"},
        {"name":"HALO TOP PEANUT BUTTER ICE CREAM 16 OZ","price":"5.99","productCode":"0085808900322"},
        {"name":"SO DELICIOUS TRUFFLE CASHEW 16 ML","price":"4.49","productCode":"0074447347621"},
        {"name":"SO DELICIOUS CREAM CASHEW 1PINT","price":"4.49","productCode":"0074447300028"},
        {"name":"MAGNUM DOUBLE CARAMEL ICE CREAM 9.13 OZ","price":"5.99","productCode":"0007756713228"},
        {"name":"SO DELICIOUS ALMOND FUDGE 4.1 ML","price":"6.49","productCode":"0074447349213"},
        {"name":"MAGNUM DARK CHOCOLATE ICE CREAM 9.12 OZ","price":"5.99","productCode":"0007756713227"},
        {"name":"TRU FRU WHOLE STRAWBERRIES 8 OZ","price":"7.99","productCode":"0085001211716"},
        {"name":"TRU FRU DARK CHOCOLATE RASPBERRIES 8 OZ","price":"7.99","productCode":"0085024100884"},
        {"name":"TRU FRU CHOCOLATE RASPBERRIES 8 OZ","price":"7.99","productCode":"0085024100883"},
        {"name":"HALO TOP CREAM ICE CREAM 16 OZ","price":"5.99","productCode":"0085808900313"},
        {"name":"SO DELICIOUS MILK MINT CHIP 1PINT","price":"4.99","productCode":"0074447347055"},
        {"name":"HALO TOP MINT CHIP ICE CREAM 16 OZ","price":"5.99","productCode":"0085808900310"},
        {"name":"HALO TOP CARMEL MACCHIATO ICE CREAM 1PINT","price":"5.99","productCode":"0085808900335"},
        {"name":"MAGNUM ICE CREAM MINI 6 COUNT","price":"7.79","productCode":"0007756700368"},
        {"name":"TRU FRU STRAWBERRY MILK CHOCOLATE 20 OZ","price":"16.99","productCode":"0085001211727"},
        {"name":"SO DELICIOUS VANILLA SANDWICH 8 OZ","price":"6.49","productCode":"0074447347211"},
        {"name":"MAGNUM RASPBERRY ICE CREAM 3 COUNT","price":"5.99","productCode":"0007756727105"},
        {"name":"HALO TOP CARAMEL ICE CREAM 16 OZ","price":"5.99","productCode":"0085808900315"},
        {"name":"HALO TOP CHOCOLATE MOCHA ICE CREAM 16 OZ","price":"5.99","productCode":"0085808900311"},
        {"name":"M&M'S PEANUT BUTTER 8.6 OZ","price":"4.99","productCode":"0004000060557"},
        {"name":"CHEEZ IT SMOKED CHEDDAR 12.4 OZ","price":"4.99","productCode":"0002410012424"},
        {"name":"TATE'S BAKE SHOP BARK CHOCOLATE 4.6 OZ","price":"5.99","productCode":"0081029100771"},
        {"name":"CLUB CRACKERS SWEET HAWAIIAN 13.7 OZ","price":"3.99","productCode":"0003010012977"},
        {"name":"CHIPS AHOY CHEWY HOT COCOA 9.9 OZ","price":"4.99","productCode":"0004400007815"},
        {"name":"CHEEZ-IT CHEESE CRACKERS12.4 OZ","price":"4.99","productCode":"0002410012418"},
        {"name":"RITZ HOLIDAY SHAPES 12.3 OZ","price":"5.79","productCode":"0004400007905"},
        {"name":"OREO SANDWICH COOKIES 8.5 OZ","price":"5.49","productCode":"0004400001473"},
        {"name":"RITZ FUDGE COVERED 7.8 OZ","price":"5.49","productCode":"0004400008042"},
        {"name":"TATE'S BAKE SHOP GINGERSNAP 6.5 OZ","price":"5.99","productCode":"0081029100797"},
        {"name":"SKITTLES POP'D SOUR STAND 5.5 OZ","price":"6.99","productCode":"0002200030128"},
        {"name":"SKITTLES POP'D ORIGINALS STAND 5.5 OZ","price":"6.99","productCode":"0002200030125"},
        {"name":"CHEEZ-IT CHEESE CRACKERS 12.4 OZ","price":"4.99","productCode":"0002410010685"},
        {"name":"PREMIUM SALTINE CRACKERS 16 OZ","price":"4.79","productCode":"0004400000057"},
        {"name":"WELCHS MIXED FRUIT 40 OZ","price":"9.99","productCode":"0003485684068"},
        {"name":"OREO CHOCOLATE COOKIES 18 OZ","price":"6.79","productCode":"0004400006023"},
        {"name":"CHIPS AHOY CHOCOLATE COOKIES 13 OZ","price":"4.99","productCode":"0004400003219"},
        {"name":"RUFFLES POTATO CHIPS 13 OZ","price":"6.49","productCode":"0002840051773"},
        {"name":"CHEETOS SNACKS CHEESE FLAVORED 15 OZ","price":"6.49","productCode":"0002840031409"},
        {"name":"RITZ ORIGINAL CRACKERS 13.7 OZ","price":"5.79","productCode":"0004400003111"},
        {"name":"HONEY MAID GRAHAM CRACKERS 14.4 OZ","price":"4.99","productCode":"0004400000463"},
        {"name":"LAYS POTATO CHIPS CLASSIC 13 OZ","price":"5.99","productCode":"0002840031041"},
        {"name":"TOWN HOUSE CRACKERS 13.8 OZ","price":"3.99","productCode":"0003010010055"},
        {"name":"NUTTER BUTTER SANDWICH COOKIES 16 OZ","price":"5.99","productCode":"0004400004631"},
        {"name":"STARRY CRANBERRY BLIZZ ZERO SUGAR 2 L","price":"2.79","productCode":"0001200024029"},
        {"name":"LA COLOMBE TRIPLE DRAFT LATTE 11 OZ","price":"2.61","productCode":"0060491300488"},
        {"name":"LA COLOMBE DRAFT LATTE VANILLA 11 OZ","price":"2.61","productCode":"0060491300490"},
        {"name":"BODYARMOR FRUIT PUNCH 12 OZ","price":"7.99","productCode":"0085817600280"},
        {"name":"GATORADE G SERIES THIRST QUENCHER 18 OZ","price":"16.15","productCode":"0005200012324"},
        {"name":"GATORADE PERFORM FRUIT PUNCH 12 OZ","price":"11.93","productCode":"0005200012196"},
        {"name":"GATORADE GLAZIER FREEZE 12 OZ","price":"11.93","productCode":"0005200012251"},
        {"name":"CELSIUS ENERGY DRINK 12 OZ","price":"23.99","productCode":"0088939200093"},
        {"name":"DIET COKE SODA POP COLA 12P 12 OZ","price":"10.99","productCode":"0004900002891"},
        {"name":"COCA-COLA SODA POP CLASSIC 12P 12 OZ","price":"12.43","productCode":"0004900002890"},
        {"name":"FOLGERS CLASSIC ROAST 33.7 OZ","price":"18.99","productCode":"0002550030418"},
        {"name":"SIGNATURE SELECT JUICE APPLE 64 OZ","price":"3.49","productCode":"0002113031008"},
        {"name":"SIGNATURE SELECT SODA CLUB 12 OZ","price":"4.50","productCode":"0002113029485"},
        {"name":"RED BULL ENERGY DRINK 12 OZ","price":"3.12","productCode":"0061126981899"},
        {"name":"TOPO CHICO MINERAL WATER 12P 12 OZ","price":"15.99","productCode":"0002113605046"},
        {"name":"PEPSI SODA COLA 12P 12 OZ","price":"12.43","productCode":"0001200080994"},
        {"name":"CRAISINS ORIGINAL  6 OZ","price":"2.99","productCode":"0003120000159"},
        {"name":"LAYS POTATO CHIPS  ORIGINAL 7.75 OZ","price":"4.99","productCode":"0002840004380"},
        {"name":"SUN-MAID RAISINS NATURAL CALIFORNIA 6 OZ","price":"3.49","productCode":"0004114312010"},
        {"name":"STACY'S BAKED PITA CHIPS 7.33 OZ","price":"5.99","productCode":"0002840056463"},
        {"name":"BLUE DIAMOND ALMONDS 6 OZ","price":"5.49","productCode":"0004157002970"},
        {"name":"O ORGANICS PECAN HALVES 10 OZ","price":"8.99","productCode":"0007989315826"},
        {"name":"FRITOS SCOOPS! CORN CHIPS 9.25 OZ","price":"5.79","productCode":"0002840058932"},
        {"name":"RITZ ORIGINAL CRACKERS 13.7 OZ","price":"5.79","productCode":"0004400003111"},
        {"name":"VICENZI LADYFINGERS 7 OZ","price":"3.99","productCode":"0070175068386"},
        {"name":"MARIANI APRICOTS MEDITERRANEAN 6 OZ","price":"3.50","productCode":"0007102214009"},
        {"name":"SMARTFOOD POPCORN CHEDDAR 6.75 OZ","price":"5.19","productCode":"0002840031401"},
        {"name":"CASA SANCHEZ MEDIUM ROJA SALSA 15 OZ","price":"5.99","productCode":"0007873200413"},
        {"name":"SIGNATURE CHERRIES DRIED 5 OZ","price":"3.99","productCode":"0002113052239"},
        {"name":"SIGNATURE PECAN PIECES 12 OZ","price":"6.99","productCode":"0002113012896"},
        {"name":"SNYDER'S OF HANOVER MINI PRETZELS 16 OZ","price":"4.49","productCode":"0007797508006"},
        {"name":"O ORGANIC FRUIT STRIPS STRAWBERRY 5 OZ","price":"3.99","productCode":"0007989312530"},
        {"name":"LOTUS COOKIES BISCOFF 8.8 OZ","price":"3.00","productCode":"0002178891629"},
        {"name":"CHEETOS CHEDDAR FLAVORED PUFFS 8 OZ","price":"4.99","productCode":"0002840007165"},
        {"name":"HELUVA GOOD! FRENCH ONION DIP 12 OZ","price":"1.99","productCode":"0007357013000"},
        {"name":"NILLA WAFERS COOKIES VANILLA 11 OZ","price":"5.49","productCode":"0004400002734"},
        {"name":"SIGNATURE APRICOTS FANCY DRIED 6 OZ","price":"2.99","productCode":"0002113052223"},
        {"name":"SUN-MAID RAISINS GOLDEN 12 OZ","price":"4.49","productCode":"0004114315160"},
        {"name":"NUTELLA BISCUITS T20 9.7 OZ","price":"5.99","productCode":"0000980083003"},
        {"name":"TOSTITOS SALSA CHUNKY MILD 15.5 OZ","price":"4.99","productCode":"0002840005597"},
        {"name":"FRITOS SCOOPS PARTY SIZE 15.5 OZ","price":"6.49","productCode":"0002840031403"},
        {"name":"TOOTSIE CHILDS PLAY 3.25 L","price":"13.99","productCode":"0007172001752"},
        {"name":"PLANTERS PEANUTS DRY ROASTED 16 OZ","price":"3.50","productCode":"0002900007325"},
        {"name":"FRITOS CORN CHIPS ORIGINAL 9.25 OZ","price":"5.79","productCode":"0002840058929"},
        {"name":"PREMIUM ORIGINAL SALTINE CRACKERS 16 OZ","price":"4.79","productCode":"0004400000057"},
        {"name":"SMARTFOOD POPCORN KETTLE CORN 7.75 OZ","price":"5.19","productCode":"0002840031400"},
        {"name":"SUN-MAID RAISINS SUN DRIED 32 OZ","price":"6.99","productCode":"0004114302917"},
        {"name":"SIM MILL COOK CR CC 5.5 OZ","price":"4.99","productCode":"0085606900519"},
        {"name":"DATES PITTED 32 OZ","price":"11.99","productCode":"0007102232119"},
        {"name":"CASA SANCHEZ MILD ROJA SALSA 15 OZ","price":"6.99","productCode":"0007873200400"},
        {"name":"PLANTERS MIXED NUTS 10.3 OZ","price":"5.99","productCode":"0002900001665"},
        {"name":"SMARTFOOD POPCORN CHEDDAR 6.25 OZ","price":"7.00","productCode":"0002840067909"},
        {"name":"OREO CHOCOLATE COOKIES 13.29 OZ","price":"4.99","productCode":"0004400006011"},
        {"name":"CHESTERS FRIES FLAMIN HOT BAG 5.25 OZ","price":"2.99","productCode":"0002840070569"},
        {"name":"GUACAMOLE SPICY 14 OZ","price":"5.99","productCode":"0081004159906"},
        {"name":"MARIANI PRUNES PROBIOTIC 7 OZ","price":"2.50","productCode":"0007102234011"},
        {"name":"BREYERS ICE CREAM VANILLA 48 OZ","price":"5.49","productCode":"0077567254238"}],
      productIceData:[
        {"name":"BREYERS NATURAL ICE CREAM VANILLA 16 OZ","price":"3.30","productCode":"0007756725423"},
        {"name":"BREYERS NATURAL ICE CREAM VANILLA 48 OZ","price":"5.59","productCode":"0077567254238"},
        {"name":"BREYERS NATURAL ICE CREAM VANILLA 48 OZ","price":"5.59","productCode":"0077567254238"},
        {"name":"BREYERS NATURAL ICE CREAM VANILLA 48 OZ","price":"5.59","productCode":"0077567254238"},
        {"name":"BREYERS NATURAL ICE CREAM VANILLA 48 OZ","price":"5.59","productCode":"0077567254238"},
        {"name":"BREYERS NATURAL ICE CREAM VANILLA 48 OZ","price":"5.59","productCode":"0077567254238"}]
    }
  },
  components: {
  },
  watch: {
    // 监听 newOrderId 变量的变化
    newOrderId(newValue) {
      if (newValue) {
        // console.log('newOrderId 变化，启动定时任务');
        this.startTimer();  // 启动定时任务
      } else {
        this.stopTimer();   // 如果 newOrderId 为空，停止定时任务
      }
    }
  },
  computed: {
    text() {
      return this.currentRate.toFixed(0) + '%';
    },
  },
  mounted() {
    this.gensFuncs();
  },
  methods: {
    pageRowsInput(event) {
      this.pageRows = event.target.value.slice(0, 2); // 限制为10位
    },
    handleInput(event) {
      this.card4No = event.target.value.slice(0, 4); // 限制为10位
    },

    printElement() {

      console.log("初始化")
      if (typeof getLodop === 'undefined') {
        console.error('LODOP未加载，请确保已正确引入 LodopFuncs.js');
        return;
      }

      for (let p = 1; p <= this.pageRows; p++) {
        this.gensFuncs();
        let LODOP = getLodop();
        if(LODOP){
          LODOP.PRINT_INIT('');
          LODOP.SET_PRINTER_INDEX(-1);
          LODOP.SET_PRINT_STYLE("FontName", "黑体")
          LODOP.SET_PRINT_STYLE("FontSize", 9);
          LODOP.ADD_PRINT_IMAGE(20,20,240,45,"<img src='/image/safeway_logo1.png'/>");
          LODOP.SET_PRINT_STYLEA(0,"Stretch",1);
          LODOP.ADD_PRINT_TEXT(75,20,240,80,"STORE "+this.storeNo+'   '+this.salesPerson);
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.ADD_PRINT_TEXT(90,20,240,80,"Main: "+this.phone);
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.ADD_PRINT_TEXT(105,20,240,80,this.address);
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.ADD_PRINT_TEXT(120,20,240,80,this.city+'   '+this.storeNo);
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.ADD_PRINT_TEXT(160,20,240,80,"GROCERY");
          LODOP.SET_PRINT_STYLEA(0,"alignment",2);
          LODOP.SET_PRINT_STYLEA(6,"Bold",1);
          var iCurLine = 170;
          if(this.showProduceData.length>0)
          for (var i = 0; i < this.showProduceData.length; i++) {
            iCurLine = iCurLine+15;
            LODOP.ADD_PRINT_TEXT(iCurLine,20,300,80,this.showProduceData[i].name);
            iCurLine = iCurLine+15;
            LODOP.ADD_PRINT_TEXT(iCurLine,30,300,80,this.showProduceData[i].productCode);
            LODOP.ADD_PRINT_TEXT(iCurLine,200,300,80,this.showProduceData[i].price+" B");
          }

          LODOP.ADD_PRINT_TEXT(iCurLine+30,40,300,80,"TAX");
          LODOP.ADD_PRINT_TEXT(iCurLine+30,200,300,80,this.taxPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine+45,20,300,80,"****BALANCE");
          LODOP.ADD_PRINT_TEXT(iCurLine+45,201,300,80,+this.totalPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine+60,20,300,80,"----------------------------------------");

          LODOP.ADD_PRINT_TEXT(iCurLine+75,20,300,80,"Debit Purchase  "+this.orderDate);
          LODOP.ADD_PRINT_TEXT(iCurLine+90,20,300,80,"CARD # ************"+this.card4No+"   PARMARY");
          LODOP.ADD_PRINT_TEXT(iCurLine+105,20,300,80,"TOTAL TRANSACTION AMOUNT:  "+this.totalPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine+120,20,300,80,"CASH BACK AMOUNT:          0.00");
          LODOP.ADD_PRINT_TEXT(iCurLine+135,20,300,80,"REF: "+this.seqNo+"   AUTH: "+this.auth);
          LODOP.ADD_PRINT_TEXT(iCurLine+150,20,300,80,"----------------------------------------");
          LODOP.ADD_PRINT_TEXT(iCurLine+165,20,300,80,"AL Debit");
          LODOP.ADD_PRINT_TEXT(iCurLine+180,20,300,80,"AID:A0000000" +this.aId5No );
          LODOP.ADD_PRINT_TEXT(iCurLine+195,20,300,80,"TVR:"+this.tvr);
          LODOP.ADD_PRINT_TEXT(iCurLine+210,20,300,80,"TSI:"+this.tsi);
          LODOP.ADD_PRINT_TEXT(iCurLine+240,50,300,80,"Debit:");
          LODOP.ADD_PRINT_TEXT(iCurLine+240,201,300,80,this.totalPrice);
          LODOP.ADD_PRINT_TEXT(iCurLine+270,50,300,80,"CHANGE:");
          LODOP.ADD_PRINT_TEXT(iCurLine+270,201,300,80,"0.00");
          LODOP.ADD_PRINT_TEXT(iCurLine+285,20,300,80,"TOTAL NUMBER OF ITEMS SOLD =   "+this.iptRows);
          LODOP.ADD_PRINT_TEXT(iCurLine+300,20,300,80,this.orderDate+" "+this.storeNo+"  "+this.codeFrist+"  "+this.codeSecend+"   "+this.codeThird);
          LODOP.ADD_PRINT_TEXT(iCurLine+330,30,300,80,"Thank you for shopping at Our Store");
          LODOP.ADD_PRINT_TEXT(iCurLine+345,90,300,80,"Come Again Son");
          LODOP.PRINT();
          //LODOP.PREVIEW();

          setTimeout(function() {
          }, 2000);
        }else{
          console.log("LODOP 无法获取")
        }
      }
    },

    gensFuncs(){
      this.showProduceData.push({ name: 'BREYERS ICE CREAM VANILLA 48 OZ', price: '5.50', productCode: '0077567254238' });
      this.showProduceData = [];
      this.codeFrist = Math.floor(Math.random() * (99 - 20 + 1) + 20);
      this.codeSecend = Math.floor(Math.random() * (99 - 20 + 1) + 20);
      this.codeThird= Math.floor(Math.random() * (9999 - 8500 + 1) + 8500);
      this.auth = Math.floor(Math.random() * (94567 - 30000 + 1) + 30000);
      this.tvr = "80000"+Math.floor(Math.random() * (90000 - 40000 + 1) + 40000);
      this.tsi= Math.floor(Math.random() * (9800 - 4800 + 1) + 4800);

      this.orderDate = this.getRandomDateTime();
      var index = Math.floor(Math.random() * this.storeData.length);
      this.storeNo =  this.storeData[index].storeNo;
      this.address =  this.storeData[index].address;
      if(this.address.length>25){
        this.address = this.address.substring(0, 25);
      }
      this.tempAddress =  this.address;
      this.phone =  this.storeData[index].phone;
      this.city =  this.storeData[index].city;
      this.zip = this.storeData[index].zip;
      this.salesPerson = "Dir "+this.storeData[index].salesPerson;
      this.iptRows = Math.floor(Math.random() * (15 - 10 + 1)) + 10;
      this.iceRows = Math.floor(Math.random() * (10 - 6 + 1)) + 6;

      this.iptRowsFull = this.iptRows < 10 ? '0' + this.iptRows : this.iptRows.toString();
      console.log("iptRows:"+this.iptRows)
      console.log("iceRows:"+this.iceRows)
      var difference = 0;
      var startCount = 0;
      var endCount = 0;
      difference =  this.iptRows - this.iceRows;
      console.log("difference:"+difference)
      var pLength=  this.productData.length
      var pIceLeng = this.productIceData.length
      var tempPrice = 0.00;
      if(difference>0){
        startCount  =  Math.floor(Math.random() * (difference - 1 + 1)) + 1;
        console.log("startCount:"+startCount)
        if(startCount == 1){
          for (let i = 1; i <= difference; i++) {
            var pIindex = Math.floor(Math.random() * (pLength-1));
            this.showProduceData.push(this.productData[pIindex])
            tempPrice = tempPrice+ parseFloat(this.productData[pIindex].price);
          }
          for(let j = 1; j <= this.iceRows; j++) {
            var pIcaIndex = Math.floor(Math.random() * pIceLeng);
            this.showProduceData.push(this.productIceData[pIcaIndex])
            tempPrice = tempPrice + parseFloat(this.productIceData[pIcaIndex].price);
          }
        }else if(startCount == difference){
          for(let j = 1; j <= this.iceRows; j++) {
            var pIcaIndex = Math.floor(Math.random() * pIceLeng);
            this.showProduceData.push(this.productIceData[pIcaIndex])
            tempPrice = tempPrice + parseFloat(this.productIceData[pIcaIndex].price);
          }
          for (let i = 1; i <= difference; i++) {
            var pIindex = Math.floor(Math.random() * (pLength-1));
            this.showProduceData.push(this.productData[pIindex])
            tempPrice = tempPrice+ parseFloat(this.productData[pIindex].price);
          }
        }else{
          endCount = difference - startCount;

          for(let y = 1; y <= startCount; y++) {
            var pIindex = Math.floor(Math.random() *(pLength-1));
            this.showProduceData.push(this.productData[pIindex])
            tempPrice = tempPrice+ parseFloat(this.productData[pIindex].price);
          }

          for(let j = 1; j <= this.iceRows; j++) {
            var pIcaIndex = Math.floor(Math.random() * pIceLeng);
            this.showProduceData.push(this.productIceData[pIcaIndex])
            tempPrice = tempPrice + parseFloat(this.productIceData[pIcaIndex].price);
          }

          for (let i = 1; i <= endCount; i++) {
            var pIindex = Math.floor(Math.random() * (pLength-1));
            this.showProduceData.push(this.productData[pIindex])
            tempPrice = tempPrice+ parseFloat(this.productData[pIindex].price);
          }

        }

      }else{
        for(let j = 1; j <= this.iceRows; j++) {
          var pIcaIndex = Math.floor(Math.random() * pIceLeng);
          this.showProduceData.push(this.productIceData[pIcaIndex])
          tempPrice = tempPrice + parseFloat(this.productIceData[pIcaIndex].price);
        }
      }
      tempPrice = parseFloat(tempPrice).toFixed(2);
      console.log("tempPrice:"+tempPrice)
      //单号
      this.seq4No = Math.floor(Math.random() * (6000000000 - 5000000000 + 1) + 5000000000);
      let num2 = Math.floor(Math.random() * (99 - 11 + 1) + 11);
      this.seqNo = this.seq4No+""+num2;

      this.auth = Math.floor(Math.random() * (80000 - 70000 + 1) + 70000);
      //条形码
      let barcodeFrist ="99";
      let barcodeSecond = Math.random().toString().slice(2, 7);
      this.barcodeValue = barcodeFrist+''+this.storeNo+""+num2+""+this.seq4No+""+this.iptRowsFull+""+barcodeSecond;
      //信用卡后四位
      /*var myArray =[1972,1191,1614,1785,1798,1516,1077,1772,1892,1379,1764,1621,1342,1397,1589,1568,1074,1722,1111,1651,1986,1212,1494,1859,1633,1601,1496,1277,1945,1552,1030,1153,1244,1708,1549,1344,1038,1086,1336,1814,1635,1484,1820,1178,1490,1808,1511,1163,1717,1324,1398,1261,1940,1833,1522,1681,1269,1775,1310,1490,1891,1759,1288,1372,1168,1572,1409,1863,1879,1167,1467,1385,1083,1058,1918,1313,1117,1292,1351,1423,1910,1283,1773,1578,1122,1024,1297,1810,1941,1412,1081,1499,1117,1345,1593,1939,1971,1267,1552,1258];
      let myIndex = Math.floor(Math.random() * myArray.length);
      this.card4No = myArray[myIndex];*/

      this.code7No = Math.random().toString().slice(2, 9);
      //打印机编号后5位
      this.aId5No = Math.floor(Math.random() * (926150 - 925160 + 1)) + 925160;
      //日期
      this.orderDate = this.getRandomDateTime();
      //行数随机
      let num13 = this.generateRandomString(13);
      this.issuer = this.iptRows+""+num13;
      this.genRows= parseInt(this.iptRows);
      this.subTotalPrice = tempPrice;
      this.taxPrice = parseFloat(tempPrice * this.tax10).toFixed(2);
      this.totalPrice = parseFloat(this.subTotalPrice) + parseFloat(this.taxPrice);
      this.totalPrice = parseFloat(this.totalPrice).toFixed(2);
      this.showIptRows = this.iptRows < 10 ? this.iptRows.toString()+'\xa0'+'\xa0' : this.iptRows.toString();

    },
    generateRandomString(num) {
      let digitsCount = 0;
      let result = '';

      while (result.length < num) {
        const randomChar = Math.random() >= 0.5 ? this.getRandomChar() : this.getRandomDigit();
        result += randomChar;
        if (randomChar >= '0' && randomChar <= '9') {
          digitsCount++;
        }
      }
      // 确保至少有4位数字
      while (digitsCount < 4) {
        const randomIndex = Math.floor(Math.random() * result.length);
        const charAtIndex = result.charAt(randomIndex);
        if (charAtIndex >= '0' && charAtIndex <= '9') {
          result = result.slice(0, randomIndex) + this.getRandomDigit() + result.slice(randomIndex);
          digitsCount++;
        }
      }
      return result;
    },
    getRandomChar() {
      return String.fromCharCode(65 + Math.floor(Math.random() * 26)); // A-Z
    },
    getRandomDigit() {
      return String.fromCharCode(48 + Math.floor(Math.random() * 10)); // 0-9
    },
    getRandomDateTime() {
      const startYear = 2016;
      const endYear = 2024;
      const startTime = new Date(startYear, 4, 21); // 2016年的4月21日
      const diff = new Date(endYear, 7, 13) - startTime; // 2024年的7月1日
      const randomTimestamp = startTime.getTime() + Math.random() * diff;
      const randomDate = new Date(randomTimestamp);
      //const month = String(randomDate.getMonth() + 1).padStart(2, '0'); // 月份从 0 开始，所以要加 1
      const year = randomDate.getFullYear();
      var tempMonth = Math.floor(Math.random() * (9 - 6 + 1)) + 6;
      if(year=='2024') {
        tempMonth = Math.floor(Math.random() * (7 - 6 + 1)) + 6;
      }
      const month = tempMonth < 10 ? '0' + tempMonth : tempMonth.toString();
      const day = String(randomDate.getDate()).padStart(2, '0');
      //const hours = String(randomDate.getHours()).padStart(2, '0');
      const tempHours =  Math.floor(Math.random() * (19 - 12 + 1)) + 12;
      const hours =  tempHours < 10 ? '0' + tempHours : tempHours.toString();
      const minutes = String(randomDate.getMinutes()).padStart(2, '0');

      return `${month}/${day}/${year} ${hours}:${minutes}`;
      // return randomDate.toISOString().slice(0, -1); // 去掉毫秒部分
    },

    // 判断设备是否为移动端
    isMobileDevice() {
      const userAgent = navigator.userAgent.toLowerCase();
      // 使用正则检查 userAgent 中是否包含手机/平板的关键字
      return /iphone|ipod|android.*mobile|windows.*phone|blackberry.*mobile|webos.*mobile/i.test(userAgent);
    },
    isMobileBrowerSize() {
      return window.innerWidth <= 768;  // 视窗宽度小于等于 768px 视为移动端
    },

  },
  beforeDestroy() {

  },
  created() {

  }
}
</script>

<style scoped>
@media print {
  body {
    font-family: "Microsoft YaHei", Times, serif;
  }
}
</style>